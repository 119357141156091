* {
    margin: 0;
    padding: 0;
}

.tezakcha {
    background: url("../src/images/Снимок экрана 2022-03-26 в 20.59.13.png") no-repeat center/cover;
    height: 84px;
}

.hero {
    height: 100vh;
    position: relative;
    background: url("../src/images/Снимок экрана 2022-03-26 в 22.41.02.png") no-repeat center/cover;
}

.container1 {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 15px;
}

.header {
    background: url("../src/images/Снимок экрана 2022-03-26 в 22.48.29.png") no-repeat center/cover;
    padding: 30px 0;
}

.navbar-link {
    padding: 10px 15px;
}

.navbar-menu {
    padding: 20px;
}

.bg-top1 {
    background: #ffbd26;
}

.link {
    background: #1b751d;
    padding: 15px 15px;
    position: absolute;
    bottom: 15px;
    right: 700px;
}
.bg-top1-2{
    background: #ffbd26;
    padding-top: 80px;
}
.home-desc{
    max-width: 500px;
    padding-top: 20px;
}
.img-iphone-home{
    width: 300px;
    height: 600px;
}
.left-side-home{
    margin-right: 220px;
}
html{
    scroll-behavior: smooth;
}
.img-iphone-home1{
    width: 400px;
    height: 600px;
}
.footer{
    background: url("../src/images/Снимок экрана 2022-03-27 в 03.09.34.png") no-repeat center/cover;
    padding: 200px;
}
.logo-store{
    width: 100px;
    height: 40px;
}
.logo-store1{

}

